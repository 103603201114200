
  
  .header {

    width:"70%";
  }
  
  .sidebar {
    display: "block";
    border: 1px solid #000000;
    height: 200px;
    align-items: center;
    float: left;
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
  }
  
  .content {
    float: right;
    height: 800px;
    display:"flex"; 
    flex-direction:"column";
  }
  .icon-button {
    position: absolute;
    top: 20px; /* Adjust as needed */
    left: 100px; /* Adjust as needed */
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 24px; /* Adjust icon size */
    z-index: 10; /* Ensure it appears on top */
}
 
.footer {
   
    clear: both;
    margin-top: 300px;
    border: 1px solid #000000;
    height: 100px;
  }
  .sticky-stopper {
    height: 100px; /* Invisible element to act as a stopper */
    margin-top: 400px; /* Adjust this based on how far down you want the sticky element to stop */
  }
